import React, {Component} from 'react'
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';

export class MapContainer extends Component {
  render() {
    return (
      <Map className = "mp" google={this.props.google} zoom={12}
      initialCenter={{
        lat: 46.047956, 
        lng: 14.524423
      }}
      disableDefaultUI
      >
      <div></div>
        <Marker onClick={this.onMarkerClick}
                name={'Current location'} />

      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyCO1Gpke1Q3tW2Ram0gJeWtCS3wp7yQ9ak')
})(MapContainer)