import React from 'react';
import './css/services.css';
import Interested from './small/interestedServices.js';
import Why from './small/reasons.js';
import WhatWeDo from './small/whatWeDo.js'
import ScrollableAnchor from 'react-scrollable-anchor';

class Services extends React.Component {

  render() {
    return (
     <ScrollableAnchor id={'services'}>
      <section id="servicess">
      <WhatWeDo />
      <Why />
      <Interested />
      </section>
      </ScrollableAnchor>
    );
  }
}

export default Services;
