import React from 'react';
import './css/testimonialStyle.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteRight, faCircle } from '@fortawesome/free-solid-svg-icons'
import ScrollableAnchor from 'react-scrollable-anchor';
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import en from './translations/en'
import slo from './translations/slo'

counterpart.registerTranslations('en', en);
counterpart.registerTranslations('slo', slo);
counterpart.setLocale('en');

export default class Slider extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
     images: [
       {text: <Translate content="testemonials.firstText"/>, clientName:"Jure Milavec", clientTitle: <Translate content="testemonials.firstClient"/>},
       {text: <Translate content="testemonials.secondText"/>, clientName:"Darja Poljanec", clientTitle: <Translate content="testemonials.secondClient"/>},
       {text: <Translate content="testemonials.thirdText"/>, clientName:"Simon Karakaš", clientTitle: <Translate content="testemonials.thirdClient"/> },
       {text: <Translate content="testemonials.fourthText"/>, clientName:"Ana Ušeničnik", clientTitle: <Translate content="testemonials.fourthClient"/>}
      
      ],
      currentIndex: 0,
      translateValue: 0,
    }
  }

  renderTableData() {
    return this.state.images.map((image, i) => {
       const { text, clientName, clientTitle } = image //destructuring
       return (
        <Slide key={i} image={text} clientName={clientName} clientTitle={clientTitle}/>
       )
    })
 }

  goToPrevSlide = () => {
    if(this.state.currentIndex === 0)
      return;
    
    this.setState(prevState => ({
      currentIndex: prevState.currentIndex - 1,
      translateValue: prevState.translateValue + this.slideWidth()
    }))
  }

  goToNextSlide = () => {
    // Exiting the method early if we are at the end of the images array.
    // We also want to reset currentIndex and translateValue, so we return
    // to the first image in the array.
    if(this.state.currentIndex === this.state.images.length - 1) {
      return this.setState({
        currentIndex: 0,
        translateValue: 0
      })
    }
    
    // This will not run if we met the if condition above
    this.setState(prevState => ({
      currentIndex: prevState.currentIndex + 1,
      translateValue: prevState.translateValue + -(this.slideWidth())
    }));
  }

  slideWidth = () => {
    console.log(document.querySelector('.slide').clientWidth);
     return document.querySelector('.slide').clientWidth
  }

  render() {
    return (
<ScrollableAnchor id={'testemonials'}>
<section id="testemonialss">
<div id="testimonialsHeader">
    <Translate content="testemonials.title" component="h1"/> 
  </div>

       <div className="slider">

        <div className="slider-wrapper"
          style={{
            transform: `translateX(${this.state.translateValue}px)`,
            transition: 'transform ease-out 0.45s'
          }}>
{/*             {
              this.state.images.map((image, i) => (
                const { text, clientName, clientTitle } = image
                <Slide key={i} image={image}/>
                ))
            } */}
            {this.renderTableData()}
        </div>
      </div> 
      <div id="cntrols">
        <LeftArrow
         goToPrevSlide={this.goToPrevSlide}
        />

        <RightArrow
         goToNextSlide={this.goToNextSlide}
        />
        </div>
</section>
</ScrollableAnchor>


    );
  }
}


const Slide = ({ image, clientName, clientTitle}) => {
return(
    <div className="slide">
    <div className="innerSlide">
<FontAwesomeIcon icon={faQuoteRight} size="lg"/>
<div id="innerSliderText">{image}</div>
<div className="client">{clientName}</div>
<div className="clientPos">{clientTitle}</div>
</div>
</div>)
}


const LeftArrow = (props) => {
  return (
    <div className="backArrow arrow" onClick={props.goToPrevSlide}>
      <FontAwesomeIcon icon={faCircle} size="sm"/>
    </div>
  );
}


const RightArrow = (props) => {
  return (
       <div className="nextArrow arrow" onClick={props.goToNextSlide}>
      <FontAwesomeIcon icon={faCircle} size="sm"/>
    </div>
  );
}
