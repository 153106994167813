import React from 'react';
import './App.css'
import {
  Routes,
  Route,
} from "react-router-dom";
import { Provider } from 'react-redux';
import AboutUs from './AboutUs.js'
import Inquiry from './Inquiry.js'
import InquiryEn from './InquiryEn.js'
import store from './store';
import HomePage from './home.js'

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  render() {
    return (
      <Provider store={store}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/inquiry" element={<Inquiry />} />
          <Route path="/inquiryEn" element={<InquiryEn />} />
        </Routes>
      {/* <Router>
        <Switch>
          <Route exact path="/about" component={<AboutUs />} />
          <Route exact path="/inquiry" component={<Inquiry />} />
          <Route exact path="/" component={<HomePage />} />
          </Switch>
        </Router> */}
      </Provider>
    );
  }
}

export default App;
