import './css/references.css'; // Import the CSS file for styling
import logo1  from './media/vatovecLogo.png';
import logo2 from './media/klaesLogo.png';
import logo3 from './media/whLogo.png';
import logo4 from './media/bebeautyLogo.png';
import logo5 from './media/vanexLogo.png';
import React, { useEffect, useRef } from 'react';
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import en from './translations/en'
import slo from './translations/slo'


const PastProjects = () => {

const projects = [
  { id: 1, name: 'Project One', logo: logo1, link: 'https://testing.vatovec.si' },
  { id: 2, name: 'Project Two', logo: logo2, link: 'https://klaes.si' },
  { id: 3, name: 'Project Three', logo: logo3, link: 'https://winkhaus.si' },
  { id: 4, name: 'Project Four', logo: logo4, link: 'https://vanexshop.com/' },
  { id: 4, name: 'Project Five', logo: logo5, link: 'https://bebeauty.si' },

];
    counterpart.registerTranslations('en', en);
counterpart.registerTranslations('slo', slo);
counterpart.setLocale('en');
    const carouselRef = useRef(null);

    useEffect(() => {
      const scrollInterval = setInterval(() => {
        if (carouselRef.current) {
          // Scroll to the right
          carouselRef.current.scrollLeft += 1;
          // Check if the carousel has scrolled to the end, and if so, reset
          if (
            carouselRef.current.scrollLeft + carouselRef.current.clientWidth >=
            carouselRef.current.scrollWidth
          ) {
            carouselRef.current.scrollLeft = 0; // Reset to start
          }
        }
      }, 20); // Scroll increment and speed
  
      return () => clearInterval(scrollInterval); // Cleanup on unmount
    }, []);
    if (!projects || projects.length === 0) {
      console.error('Projects array is empty or not loaded properly.');
      return <div>No projects to display</div>;
    }

  return (
    <div className="past-projects">
      <Translate content="inquiry.carousel.title" component="h2"/> 
      <Translate content="inquiry.carousel.subTitle" component="p" className="subheading"/> 
      <div className="carousel" ref={carouselRef}>
      {projects.map((project, index) => (
         <div className="carousel-item">
            <a href={project.link} target="_blank" rel="noopener noreferrer">
              <img src={project.logo} width="100" height="50" alt={project.name} className="project-logo" />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PastProjects;