import React from 'react';
import '../css/services.css'
import { ReactComponent as WhatSvg1 } from '../css/whatWeDo1.svg'
import { ReactComponent as WhatSvg2 } from '../css/whatWeDo2.svg'
import { ReactComponent as WhatSvg3 } from '../css/whatWeDo3.svg'
import { ReactComponent as WhatSvg4 } from '../css/whatWeDo4.svg'
import { ReactComponent as WhatSvg5 } from '../css/whatWeDo5.svg'
import { ReactComponent as WhatSvg6 } from '../css/whatWeDo6.svg'
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import en from '../translations/en'
import slo from '../translations/slo'

counterpart.registerTranslations('en', en);
counterpart.registerTranslations('slo', slo);
counterpart.setLocale('en');

const WhatWeDo = (props) => {
  return (  
    <div className ="whatWD">
<div id="innerWhatWD">
<div id="WhatWDTitle">
<Translate content="services.title" component="h2"/>
</div>
<div id="WhatWDContainer">
<div className="WhatWDInnerContainer">
	<div className="WhatWDNode">
    <div className="WhatWDIcons">
      <WhatSvg1 />

    </div>
    <div className="WhatWDInnerNode">
    <Translate content="services.subtitle1" component="h2"/>
    <div className="WhatWDText">
    <Translate content="services.storitev1"/></div></div>
	</div>
  		<div className="WhatWDNode">
        <div className="WhatWDIcons">
          <WhatSvg2 />

        </div>
		    <div className="WhatWDInnerNode">
        <Translate content="services.subtitle2" component="h2"/>
    <div className="WhatWDText">
    <Translate content="services.storitev2"/></div></div>
	</div>
  	<div className="WhatWDNode">
      <div className="WhatWDIcons">
<WhatSvg3 />

      </div>
    <div className="WhatWDInnerNode">
		<Translate content="services.subtitle3" component="h2"/>
    <div className="WhatWDText">
    <Translate content="services.storitev3"/></div></div>
	</div>
  </div>
  
 <div className="WhatWDInnerContainer">
		<div className="WhatWDNode">
      <div className="WhatWDIcons">
<WhatSvg4 />

      </div>
    <div className="WhatWDInnerNode">
		<Translate content="services.subtitle4" component="h2"/>
    <div className="WhatWDText">
    <Translate content="services.storitev4"/>
</div></div>
	</div>
    		<div className="WhatWDNode">
          <div className="WhatWDIcons">
            <WhatSvg5 />
          </div>
    <div className="WhatWDInnerNode">
		<Translate content="services.subtitle5" component="h2"/>
    <div className="WhatWDText">
    <Translate content="services.storitev5"/>
    </div></div>
	</div>
    	<div className="WhatWDNode">
            <div className="WhatWDIcons">
      <WhatSvg6 />

    </div>
    <div className="WhatWDInnerNode">
	  <Translate content="services.subtitle6" component="h2"/>
    <div className="WhatWDText">
    <Translate content="services.storitev6"/>
</div></div>
	</div>
</div>
</div>
</div>
</div>
  );
};

export default WhatWeDo;
