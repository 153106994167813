import React from 'react';
import './css/footerStyle.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faLinkedinIn, faMediumM } from '@fortawesome/free-brands-svg-icons'
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import en from './translations/en'
import slo from './translations/slo'

counterpart.registerTranslations('en', en);
counterpart.registerTranslations('slo', slo);
counterpart.setLocale('en');

const Footer = (props) => {
  return (
    <div id="footer">
      <div className="coppyright">
      <div className="leftRightC"><Translate content="footer.coppyright"/></div>
      <div className="leftRightC"> 
      <a href="https://www.facebook.com/BackendProgramming" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebookF} size="2x"/></a>
      <a href="https://www.linkedin.com/company/b-programming/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedinIn} size="2x"/></a>
      <a href="https://medium.com/@b.programming" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faMediumM} size="2x"/></a>
       </div>
        </div>
      <div className="footerMenu">
      <ul>
      {/* <Translate content="footer.cookies" component="li"/> */}
      <Translate content="footer.privacyPolicy" component="li"/> 
      <Translate content="footer.terms" component="li"/>
        <li><Translate content="footer.language"/><br />
            <select value={props.lang} onChange={(e) => props.onLangChange(e)}>
            <option value="en">en</option>
            <option value="slo">slo</option>
          </select>
        </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;