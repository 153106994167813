import React from 'react';
import '../css/services.css'
import { ReactComponent as ReasonsSvg } from '../css/services.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import en from '../translations/en'
import slo from '../translations/slo'

counterpart.registerTranslations('en', en);
counterpart.registerTranslations('slo', slo);
counterpart.setLocale('en');

const Why = (props) => {
  return (
<div id="reasons">  
  <div id="reasonsTitle">
  <Translate content="services.reasons.title" component="h2"/>
</div>
  <div id="reasonsContent">
 <div id="reasonsImg">
 <ReasonsSvg />
 </div>
  
   <div id="reasonsText">
     <div className="reasonPoint"><FontAwesomeIcon icon={faArrowRight} size="sm"/><Translate content="services.reasons.reason1"/></div>
     <div className="reasonPoint"><FontAwesomeIcon icon={faArrowRight} size="sm"/><Translate content="services.reasons.reason2"/></div>
     <div className="reasonPoint"><FontAwesomeIcon icon={faArrowRight} size="sm"/><Translate content="services.reasons.reason3"/></div>
     <div className="reasonPoint"><FontAwesomeIcon icon={faArrowRight} size="sm"/><Translate content="services.reasons.reason4"/></div>
     <div className="reasonPoint"><FontAwesomeIcon icon={faArrowRight} size="sm"/><Translate content="services.reasons.reason5"/></div>
     <div className="reasonPoint"><FontAwesomeIcon icon={faArrowRight} size="sm"/><Translate content="services.reasons.reason6"/></div>
     <div className="reasonPoint"><FontAwesomeIcon icon={faArrowRight} size="sm"/><Translate content="services.reasons.reason7"/></div>
     <div className="reasonPoint"><FontAwesomeIcon icon={faArrowRight} size="sm"/><Translate content="services.reasons.reason8"/></div>
     </div>
</div>
</div>
  );
};

export default Why;