import React from 'react';
import './css/partnersStyle.css';
import { ReactComponent as Klaes } from './css/Klaes.svg';
import { ReactComponent as WhLogo } from './css/whLogo.svg';
import { ReactComponent as StLogo } from './css/st.svg';
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import en from './translations/en'
import slo from './translations/slo'

counterpart.registerTranslations('en', en);
counterpart.registerTranslations('slo', slo);
counterpart.setLocale('en');

const Partners = () => {

return (
<div id="partners">
<div id="partnersTitle">
<Translate content="partners" component="h2"/></div>
<div id="partnersLogos">
<div className="partnerLogo1">
<a href="http://klaes.si" target="_blank" rel="noopener noreferrer">
<Klaes/>
</a>
</div>
<div className="partnerLogo2">
<a href="http://winkhaus.si" target="_blank"rel="noopener noreferrer">
<WhLogo/>
</a>
</div>
<div className="partnerLogo3">
<a href="http://stmoto.si" target="_blank" rel="noopener noreferrer" >
<StLogo/>
</a>
</div>
</div>
</div>
);
};
export default Partners