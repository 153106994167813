export default {
  header: {
    home: "HOME",
    whatWeDo:"WHAT WE DO",
    aboutUs:"ABOUT US",
    testemonials:"TESTEMONIALS",
    contact:"CONTACT"
   },
  home: {
    title: 'Imagine...',
    subtext: 'automating your business with a single web application',
    heroButton: 'Learn more'
  },
  services: {
    title: 'What we do',
    subtitle1:'Web application development services',
    storitev1:'From cloud based financial applications to large scale e-commerce platforms you can count on us to deliver the highest quality services on the market',
    subtitle2:'Custom website development services',
    storitev2:"If you're looking to impress your clients and outperform your rivals then let us make sure you'll keep expanding your market share",
    subtitle3:'Data visualization & analytics tools integration',
    storitev3:"Data now being world's most valuable asset means having a way to collect, sort and present it is crucial in sustainably growing your business",
    subtitle4:'Web design and online branding services',
    storitev4:'From brand identities to web design we help you tailor your brand to your selected audience and prepare it to withstand scalability issues',
    subtitle5:'Social media marketing', 
    storitev5:'we use your analytics data to target, build and maintain a specific demographic audience across relevant social media platforms',
    subtitle6:'Conversion rates optimization services',
    storitev6:'Achieve better conversion rates by implementing carefully designed sales funnels that guide your clients through your website or web application',
    reasons: {
     title:"Why choose our services",
     reason1:"Expertise & Experience",
     reason2:"Tailored Solutions",
     reason3:"High-Quality Standards",
     reason4:"User-Centric Design",
     reason5:"SEO Optimization",
     reason6:"Scalability & Flexibility",
     reason7:"Timely Delivery",
     reason8:"Ongoing Support & Maintenance"

    }
},
interestedServices:{
  interested: "Let us help you grow your business",
  button: "Get in touch"
},
partners: "Our partners",
aboutInner: 'More details',
testemonials: {
    title: 'Our Happy Customers',
    firstText: 'The best price to value ratio',
    firstClient: 'CEO of Stepinside',
    secondText: 'Drastically improved performance',
    secondClient: 'Co-owner of Jazsemvredu',
    thirdText: 'Our new website looks great',
    thirdClient: 'CEO of Alphacrypt',
    fourthText: 'Quick to implement all changes',
    fourthClient: 'Owner of Nikolajferdinand'
  },
  contact:{
  title:"Talk with us",
  first:"Name",
  second:"Email",
  third:"How can we help you?",
  button:"Send your inquiry",
  address:"Address",
  phone:"Phone",
  email:"Email",
  consultation: "Request free consultation",
  button2:"Submit"
  },
  footer: {
    coppyright:"coppyright @ 2019 B.P. d.o.o. All Rights Reserved",
    cookies: 'Cookies',
    privacyPolicy: "Privacy policy",
    terms: "Terms of service",
    language:"Language: "
  },
inquiry:{
  contact: {
    title: "Ready to Skyrocket Your Website’s Success? Get a FREE, No-Obligation €500 Website Audit!",
    title1: "Let’s Bring Your Vision to Life!",
    title2: "Share your project details, and we’ll provide custom solutions just for you. Act now and unlock a limited-time discount with select",
    title3: "DISCOUNT CODES.",
    getQuoteButton: "Get a Free Quote Now",
    subtitle1:"Transform Your Online Presence with Expert Web Development!",
    promoText1: "Welcome to B.Programming, the #1 choice for businesses ready to dominate the online world! 🚀",
    promoText2: "Unlock valuable insights and elevate your online presence with a comprehensive audit of your website, absolutely FREE!",
    promoText3: "Don't miss this chance to elevate your brand and stand out in the digital crowd!",
    submitButton:"Unlock Your Site’s Potential – Get Your Free Website Audit Now!"
  },
  carousel:{
    title:"Trusted by Leading Brands",
    subTitle:"We’ve collaborated with top companies to deliver exceptional results."
  }
}
}
