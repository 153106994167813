import React from 'react';
import './App.css'
import { Provider } from 'react-redux';
import Header from './components/header.js'
import Footer from './components/footer.js'
import AboutUs from './AboutUs.js'
import Home from './components/home.js';
import Posts from './components/posts.js';
import PostForm from './components/postForm.js';
import Testemonials from './components/testimonials';
import About from './components/about';
import Services from './components/services';
import store from './store';
import CookieConsent from "react-cookie-consent";
import counterpart from 'counterpart';
import en from './components/translations/en'
import slo from './components/translations/slo'

counterpart.registerTranslations('en', en);
counterpart.registerTranslations('slo', slo);
counterpart.setLocale('en');

class HomePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lang: 'en'
    }
  }


componentDidCatch(error, errorInfo) {
  // You can also log the error to an error reporting service
  console.log(error);
  console.log(errorInfo);
}
onLangChange = (e) => {
  this.setState({lang: e.target.value});
  counterpart.setLocale(e.target.value);
 }

  render() {
    return (
      <Provider store={store}>
      <div className="App">
      <Header lang={this.state.lang} onLangChange={this.onLangChange}/>
       <Home lang={this.state.lang} onLangChange={this.onLangChange}/>
        {/* <Posts /> */}
          <Services lang={this.state.lang} onLangChange={this.onLangChange}/>
          <About lang={this.state.lang} onLangChange={this.onLangChange}/>
          <Testemonials lang={this.state.lang} onLangChange={this.onLangChange}/>
         <PostForm lang={this.state.lang} onLangChange={this.onLangChange}/> 
         <CookieConsent>
    This website uses cookies to enhance the user experience.
</CookieConsent>
      <Footer lang={this.state.lang} onLangChange={this.onLangChange} />
      </div>
      </Provider>
    );
  }
}

export default HomePage;