import React from 'react';
import '../css/services.css'
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import en from '../translations/en'
import slo from '../translations/slo'

counterpart.registerTranslations('en', en);
counterpart.registerTranslations('slo', slo);
counterpart.setLocale('en');

const InterestedServices = (props) => {
  return (
    <div id="interestedServices">
<div id="interestedInnerServices">
<div id="interestedTitleServices">
<Translate content="interestedServices.interested" component="h2"/><br />
<div id="interestedButtonServices">
<button href="#contact" id="getInTouch"><Translate content="interestedServices.button"/></button>
</div>
</div>
</div>
</div>
  );
};

export default InterestedServices;
