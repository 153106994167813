import React from 'react';
import './components/css/aboutUs.css'
import Header from './components/header.js'
import Footer from './components/footer.js'
import AboutUsImg  from './components/css/bannerEng.png'
import counterpart from 'counterpart';
import en from './components/translations/en'
import slo from './components/translations/slo'

counterpart.registerTranslations('en', en);
counterpart.registerTranslations('slo', slo);
counterpart.setLocale('en');

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lang: 'en',
    }
  }
  
onLangChange = (e) => {
  this.setState({lang: e.target.value});
  counterpart.setLocale(e.target.value);
 }

  render() {
    const bg = {
      backgroundImage: `url(${AboutUsImg})`
    };
    return (
      <div className="AboutUs">
          <Header lang={this.state.lang} onLangChange={this.onLangChange}/>
          <div id="AboutUsText">
             <div id="AboutUsTextTitle">
                <h2>About us</h2>
             </div>
             <div id="AboutUsImage" style={bg}></div>
             <div id="AboutUsTextt">
            <p>What started as a simple calling to improve website quality and performance as well as its reach in the local market, quickly outgrew its initial calling and became an alliance between a web developer working in the financial sector and a marketing agent working in the web development sector. <br /><br />
And so out of the common goal for building premium online brands and complex web applications Back-end Programming or B. Programming was born. Our team aims to reduce your business costs to a minimum and increase your market share at the same time by automating significant parts of your workflow online and providing a user-friendly UI focused on achieving the highest possible conversion rates for your website or web application. <br /><br />
Our main focus is providing our clients with the best ratio between quality and price and with it making sure that they remain the leading brand in their branch. <br /><br />
Whether you have an idea for an online business or are simply looking to reduce the costs of your existing business and extend your reach & improve your conversion rates you’ll find out that we’re the ones worth contacting. <br /><br />
Let us help you achieve your goal like we helped so many before.</p>
             </div>
          </div>
          <Footer lang={this.state.lang} onLangChange={this.onLangChange} />
      </div>
    );
  }
}

export default App;