import { FETCH_POSTS, NEW_POSTS, FETCH_BY_NAME, FETCH_MEDIUM } from './types';

export const getMediumPosts = (userName) => dispatch => {
  fetch('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@b.programming')
  .then(res => res.json())
  .then(data => (dispatch({
    type: FETCH_MEDIUM,
    payload: (data.items).filter(item => item.categories.length >= 0)
}))  
  )
 }

export const getUserName = (name) => dispatch => {
  const myURL = new URL(name, 'https://bprogramming.com/home/login/id/'); 
  fetch(myURL)
     .then(res => res.json())
     .then(res => dispatch({
         type: FETCH_BY_NAME,
         payload: res[0]
     })
     //console.log(res)
   );
 }

 export const getUser = () => dispatch => {
 fetch('https://bprogramming.com/home/login/5d4b4d87a67dfa68b14a1a50')
    .then(res => res.json())
    .then(result => dispatch({
        type: FETCH_POSTS,
        payload: result
    })
  ); 
}


export const login = (details) => dispatch => {
 var formBody = [];
 for (var property in details) {
 var encodedKey = encodeURIComponent(property);
 var encodedValue = encodeURIComponent(details[property]);
 formBody.push(encodedKey + "=" + encodedValue);
 }
 formBody = formBody.join("&");
 
 fetch('https://bprogramming.com/home/login/create', {
 method: 'POST',
 headers: {
   'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
 },
 body: formBody
 })
 .then(res => res.json())
 .then(res => dispatch({
  type: NEW_POSTS,
  payload: res
})
  );
}
